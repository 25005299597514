/* @flow */

import * as React from 'react'

import { VerifyTemplate } from '../templates'
import { getQueryVariable } from './../utils'

type Props = {
  location: {
    href: string,
    search: string,
  },
}

export default function Verify(props: Props) {
  const { location } = props
  const maskedPhoneNumber = getQueryVariable(location, 'phone_number') || '+33 6* ** ** ** **'
  return (
    <VerifyTemplate
      url={location.href}
      redirectUri={getQueryVariable(location, 'redirect_uri')}
      requestId={getQueryVariable(location, 'request_id')}
      name="Wino"
      title="Vérification en 2 étapes"
      description={`Pour assurer la sécurité de votre compte, saisissez le code de vérification reçu sur votre téléphone (${maskedPhoneNumber})`}
    />
  )
}
